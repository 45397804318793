<template>
<div>
  <div class="bigs">
      <ul>
          <li>
              <router-link to="/aa">
                <p>CSGO箱子</p>
              </router-link>
          </li>
          <li>
              <router-link to="">
                <p>Roll房</p>
              </router-link>
          </li>
          <li>
              <router-link to="">
                <p>福利箱</p>
              </router-link>
          </li>
          <li>
              <img src="../../assets/img/logo.6a88e67b.png" class="logo-item">
          </li>
          <li>
              <router-link to="">
                <p>积分商城</p>
              </router-link>
          </li>
          <li>
              <router-link to="">
                <p>合作中心</p>
              </router-link>
          </li>
          <li>
              <router-link to="">
                <p>充值中心</p>
              </router-link>
          </li>
          <li class="loginData">
              <div class="login" v-if="istoken">
                  <el-row>
                    <el-button type="primary" plain @click="handleZhu">注册</el-button>
                    <el-button type="warning" plain @click="handleD">登录</el-button>
                  </el-row>
              </div>
              <div class="islogin" v-else>
                  <div class="islogin_wallet">
                      <p>余额:100</p>
                      <p>积分:100</p>
                    </div>
                  <div class="islogin_data">
                      <!-- <img src="../../assets/img/bigimg.jpg" alt=""> -->
                      <p>abc</p>
                  </div>
                  <div class="islogin_out">
                      <p>充值</p>
                      <p @click="isloginOut">退出</p>
                  </div>
              </div>
          </li>
      </ul>
  </div>

  <div class="shouji">
      1232312
  </div>
</div>
</template>

<script>
import { Button } from 'element-ui';
export default {
    name:'Tabs',
    data(){
        return{
            istoken:true,
        }
    },
    components:{
        "el-button":Button
    },
    mounted(){
        console.log(this.$route.path);
        console.log(this.$refs.refss);
    },
    methods: {
        handleZhu(){
            this.istoken = false;
        },
        handleD(){
            this.istoken = false;
        },
        isloginOut(){
            this.istoken = true
        }
    },

}
</script>

<style lang='less' scope>
@media screen and (max-width: 775px) {
  .bigs {
    display: none;
  }
  .shouji{
    display: block !important;
}
}
.shouji{
    display: none;
}
.bigs{
    
    margin-top: 10%;
    ul{
        padding-left:8%;
        padding-right:2%;
        display: flex;
        width: 90%;
        justify-content: space-between;
        background-color: #000000;
        align-items: center;
        color: #FFFFFF;
        font-weight:600;
        position: fixed;
        top: 0;
        opacity: .8;
        span{
            font-size: 12px;
            font-weight:100;
            color: #A1A9A6;
        }
        li:hover{
            color: #00DBFF;
            border-bottom: 4px #ff9838 solid ;
            span{
            color: #00DBFF;
        }
        }
        li{
            padding: 1% 0;
            display: block;
            text-align: center;
            height: 100%;
            // border-bottom: 2px rgb(206, 193, 21) solid ;
        }
        li:nth-child(4){
            border-bottom:none
        }
        li:nth-child(8){
            border-bottom:none
        }
    }
}
.logo-item{
    width: 150px;
}
.loginData:hover{
    color: #FFFFFF !important;
}
.login{
    button{
        padding: 5px  10px !important;
    }
    span{
        color: #000 !important;
        font-size: 13px !important;
        font-weight:600 !important;
    }  
}
.islogin{
    display: flex;
    .islogin_wallet{
        p{
            font-weight:100 !important;
            font-size: 13px !important;
            padding-top: 8px;
        }
    }
    .islogin_data{
        padding: 0 10px;
        img{
            width: 30px;
            height: 30px;
            border-radius: 50px;
        }
    }
    .islogin_out{
        p{
            cursor:pointer;
            font-weight:100 !important;
            font-size: 13px !important;
            padding-top: 8px;
        }
    }
}

.popup-bg {
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    display: none;
}
</style>