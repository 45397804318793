<template>
<div v-if="openAnimation">
<!-- <div> -->
    <div>
        <div class="title__content" >
            <template v-if="zhongjiangin">
            <p class="title_content_p" > {{inListadd[0].p_title}} </p>
            </template>
        </div>
        <div class="rollboxback">
            <div class="rollbox" >
                <div class="line"></div>
                <div class="line2" v-if="curr >= 2"></div>
                <div class="line3" v-if="curr >= 3"></div>
                <div class="line4" v-if="curr >= 4"></div>
                <div class="line5" v-if="curr >= 5"></div>

                <div class="rollbox_data_li">
                <table>
                    <tbody>
                        <tr id="loadout">
                            <td v-for="(item,index) in arr2" :key="index">
                                <div class="rollbox_data">
                                    <div class="rollbox_data_img" :style="{backgroundImage:'url('+item.back_logo+')'}">
                                        <img :src=item.logo>
                                    </div>
                                    <h4>{{item.title}}</h4>
                                    <p>{{item.type_title}}</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div> 
            </div>
            <!-- <div class="rollboxs">
                <img src="../assets/img/imgsDrawprizesimgs/chou.png" alt="">
            </div> -->
        </div>
        <div class="botton_fun">
            <el-row> 
                <el-button type="warning" @click="outopen">退出动画</el-button>
            </el-row>
            <el-switch
            v-model="value1"
            @change="handdonghua(value1)"
            active-text="声音">
            </el-switch>
            <div class="rollboxs">
                <img src="../assets/img/imgsDrawprizesimgs/chou.png" alt="">
            </div>
        </div>
    </div>
    <!-- <button @click="open">变换动画</button>-->
    <!-- <button @click="outopen">关闭变换动画</button> -->
    <!-- <button @click="voiceplay">播放</button> -->
    <!-- <audio id="audios" src="../assets/mp3/1.mp3"></audio> -->

</div>
</template>

<script>
import { Button ,Row,Switch} from 'element-ui';

export default {
    components:{
        "el-button":Button,
        "el-row":Row,
        "el-switch":Switch,
    },
    props:['isDrawprimgs','zhongjiang','inListadd','value2','curr','zhongjiangin'],
    watch:{
        isDrawprimgs(){
            // console.log(this.zhongjiang);
            this.arr2 = this.inListadd.concat();
            // this.arr2.splice(75,1,this.zhongjiang)
            for(let i = 0; i < this.curr;i++){
                let j = 75
                if(this.curr == 3 || this.curr == 4){
                    j = 74
                }else if(this.curr == 5){
                    j = 73
                }
                this.arr2.splice(j+i,1,this.zhongjiangin[i])
            }
            // console.log(this.inListadd); //克隆一个新的数组给他替换 不然会一直累加
            // console.log(this.arr2);
            // console.log(this.curr);
            this.openAnimation = true
            this.timingopen()
            this.value1 = this.value2
        }
    },
    data(){
        return{
            openAnimation:false,//是否打开动画
            ct:null,//定时器
            value1:!this.value2,//声音
            arr2:[],

        }
    },
    mounted(){
        // console.log(this.isDrawprimgs);
        // console.log(this.testss);
        // this.$refs.audio.play()
        // document.getElementById('audio').play();
        
    },
    methods:{
        timingopen(){
            this.ct = setTimeout( () =>{
                // this.$refs.audioz.pause()
                this.openAnimation = false
                this.$emit('update:isoutDrawp',true)
                this.$emit('update:iscase',true)
                document.getElementById('audios').pause()
            },10800)
        },
        open(){
            this.openAnimation = true
            this.timingopen()
        },
        outopen(){
            this.openAnimation = false
            clearTimeout(this.ct)
            this.$emit('update:isoutDrawp',true)
            this.$emit('update:iscase',true);
            document.getElementById('audios').pause()
        },
        handdonghua(value){
            if(value){
                document.getElementById('audios').play();
            }else{
                document.getElementById('audios').pause()
            }
        }
    }

}
</script>

<style lang="less" scoped>
.rollbox {
    // box-shadow:0px 0px 5px#FFCE39 inset ;
    margin: 0 auto;
    height: 200px;
    min-width: 1200px;
    // background: #000;
    // border: 1px solid #FFCE39;
    overflow-x: auto;
    overflow: hidden;
    position: relative;
    // padding: 0;
    border-radius: 5px;
    // min-width: 600px;
    max-width: 1200px;
    z-index: 3;
}
.line {
    width: 56px;
    height: 205px;
    left: 47.7%;
    position: absolute;
    // background: #EAD51D;
    // opacity: 0.6;
    z-index: 2;
    background-image: url("../assets/img/imgsDrawprizesimgs/xian.png");
    background-repeat: no-repeat; 
    background-size: 100% 100%; 
}
.line2{
    width: 56px;
    height: 205px;
    left: 65%;
    position: absolute;
    // background: #EAD51D;
    // opacity: 0.6;
    z-index: 2;
    background-image: url("../assets/img/imgsDrawprizesimgs/xian.png");
    background-repeat: no-repeat; 
    background-size: 100% 100%; 
}
.line3{
    width: 56px;
    height: 205px;
    left: 30.5%;
    position: absolute;
    // background: #EAD51D;
    // opacity: 0.6;
    z-index: 2;
    background-image: url("../assets/img/imgsDrawprizesimgs/xian.png");
    background-repeat: no-repeat; 
    background-size: 100% 100%; 
}
.line4{
    width: 56px;
    height: 205px;
    left: 82%;
    position: absolute;
    // background: #EAD51D;
    // opacity: 0.6;
    z-index: 2;
    background-image: url("../assets/img/imgsDrawprizesimgs/xian.png");
    background-repeat: no-repeat; 
    background-size: 100% 100%; 
}
.line5{
    width: 56px;
    height: 205px;
    left: 13.3%;
    position: absolute;
    // background: #EAD51D;
    // opacity: 0.6;
    z-index: 2;
    background-image: url("../assets/img/imgsDrawprizesimgs/xian.png");
    background-repeat: no-repeat; 
    background-size: 100% 100%; 
}
#loadout{
    position:absolute;
    animation-name: example;
    animation-duration: 10s;
    animation-fill-mode:forwards ;
    animation-timing-function:ease;
}
@keyframes example{
    // 0%{left: 0px;}
    // 50%{left: 0px;}  
    // 100%{left: 0px;} 
    from{left: 0px;}
    to{left: -15027px;}
}
.rollbox_data_li{
    display: flex;    
}
.rollbox_data{
    margin-right: 5px;
    border-radius: 5px;
    width: 200px;
    height: 160px;
    background-color: #201A0E;
    text-align: center;
    margin-top: 15px;
    color: #DEDDDB;
    p{
        font-size: 12px;
    }
    h4{
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
}
.rollbox_data_img{
    background-repeat:no-repeat;
    background-size: 100% 100%;
    height: 100px;
    border-radius: 5px;
    background-image: url('../assets/img/imgsDrawprizesimgs/2.png');
    img{
        height: 100px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}
.title__content{
    margin: 0 auto;
    text-align: center;
    line-height: 80px;
    color: #ffffff;
    font-size: 22px;
    width: 150px;
    height: 80px;
    background-image: url('../assets/img/imgsDrawprizesimgs/1.png');
    background-repeat:no-repeat;
    background-size: 100% 100%;
    margin-bottom: 50px;
}

/deep/ .el-row{
    padding-right: 100px;
}
.botton_fun{
    display: flex;
    justify-content: center;
    padding-top: 42px;
    align-items: center;
    position: relative;
    // z-index: ;
}
//在退出动画定位
.rollboxs{
    position: absolute;
    bottom: -25px;
}
//设置层级
/deep/ .el-row{
    z-index: 5;
}
/deep/ .el-switch{
    z-index: 5;
}
.title_content_p{
    font-size: 22px;
}
.big{
    background-image: url("../assets/img/imgsDrawprizesimgs/chou.png");
}
</style>